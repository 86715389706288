import {
  faAlignJustify,
  faCalendar,
  faCalendarPlus,
  faCheck,
  faCoffee,
  faCube,
  faCubes,
  faExclamationTriangle,
  faGlobeAmericas,
  faHome,
  faImage,
  faImages,
  faInfoCircle,
  faKey,
  faLock,
  faNewspaper,
  faPen,
  faPlus,
  faQuestionCircle,
  faSearch,
  faTimes,
  faTv,
  faUndo,
  faUser,
  faTrash,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';

export default {
  defaultOptions: {
    locale: 'en-US',
  },
  availableLocales: ['en-US', 'fi-FI', 'es-ES', 'sv-SE', 'ru-RU'],
  importLocales: ['fi-FI', 'es-ES', 'sv-SE', 'ru-RU'], // we don't want import to be available for en-US
  permissions: {
    availableTargets: ['admin', 'cdj', 'locale', 'type', 'localization'],
    availableAccesses: ['read', 'write'],
    defaultPermissions: [ // these are not checked by backend (always read permission, write denied only by frontend)
      { target: 'cdj', value: '*', access: 'read' },
      { target: 'locale', value: '*', access: 'read' },
    ],
    newPermissionDefault: '*',
    cdjDefaultLayerValue: 'default',
  },
  importableAssetContentTypes: ['audioContent', 'image', 'document', 'video'],
  proxy: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/graphql`,
  authEndpoint: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
  icons: [
    faAlignJustify,
    faCalendar,
    faCalendarPlus,
    faCheck,
    faCoffee,
    faCube,
    faCubes,
    faExclamationTriangle,
    faGlobeAmericas,
    faHome,
    faImage,
    faImages,
    faInfoCircle,
    faKey,
    faLock,
    faNewspaper,
    faPen,
    faPlus,
    faQuestionCircle,
    faSearch,
    faTimes,
    faTv,
    faUndo,
    faUser,
    faTrash,
    faArrowUp,
    faArrowDown,
  ],
  // Enable SSO if AAD tenant information is available
  authenticationMethods: ['sso', 'local'],
  // otherwise uses only title-field
  typesSearchedFromAnyField: ['any', 'image'],
};
