const itemName = 'token';

/**
 * Stores token in sessionStorage. This is not recommended for all
 * scenarios (cross site scripting vulnerabilities), but we want to enable page
 * refreshes without relogin.
 *
 * Store should be kept non-persistent and not kept therefore there.
 */
export default {
  getToken: () => sessionStorage.getItem(itemName),
  setToken: token => sessionStorage.setItem(itemName, token),
  isAuthenticated: () => {
    if (sessionStorage.getItem(itemName)) {
      return true;
    }
    return false;
  },
  removeToken: () => sessionStorage.removeItem(itemName),
};
