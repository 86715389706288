// TODO: find a working solution for this
const levels = {
  debug: 4,
  info: 3,
  warn: 2,
  error: 1,
};

let level = 3;

export default {
  setLevel: (logLevel) => {
    level = levels[logLevel] || 3;
  },
  debug: (str) => {
    if (level >= 4) console.log(str);
  },
  info: (str) => {
    if (level >= 3) console.log(str);
  },
  warn: (str) => {
    if (level >= 2) console.log(str);
  },
  error: (str) => {
    if (level >= 1) console.log(str);
  },
};
