import Vue from 'vue';
import Vuex from 'vuex';
// import Entity from './types/Entity';
// import TypeDef from './types/TypeDef';
// import RelatedTypes from './types/RelatedTypes';
import VuexI18n from 'vuex-i18n';
import { set } from 'lodash';
import GraphqlApi from './api/GraphqlApi';
import AuthApi from './api/AuthApi';
import auth from './auth';
import router from './router';
import PermissionList from './types/PermissionList';
import { getLocaleSettings, getTags, addTag, updateUserPerspectivePreferences, getBackendConfigurationByKey } from './api/apolloQueries';

import config from './config';
import log from './browserlog';

// languages
import enUS from './i18n/en-US.json';

const api = new GraphqlApi(config.proxy);
const authApi = new AuthApi(config.authEndpoint);

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    model: {},
    typeDefs: [],
    layeringSettings: {},
    localizationStatistics: {},
    activeLocales: [],
    tags: [],
    perspectivesVisible: null,
    options: {
      ...config.defaultOptions,
    },
    user: {},
    stack: [],
  },
  getters: {
    locale: state => state.options.locale,
    availableLocales: state => state.activeLocales,
    tags: state => state.tags,
    perspectivesVisible: state => state.perspectivesVisible,
    userPermissions: state => state.user.permissions,
    layers: state => state.layeringSettings.layers || [],
    localizationStatistics: state => state.localizationStatistics || {},
    previousStackItem: state => state.stack[state.stack.length - 2],
    lastStackItem: state => state.stack[state.stack.length - 1],
    userPerspectivePreferences: state => state.user.perspectivePreferences,
  },
  mutations: {
    SET_MODEL_VALUE(state, { fieldId, newValue }) {
      const freshModel = {
        ...state.model,
      };
      set(freshModel, fieldId, newValue);
      state.model = freshModel;
    },
    SET_TYPEDEFS(state, typeDefs) {
      state.typeDefs = typeDefs;
    },
    SET_AVAILABLE_LOCALES(state, locales) {
      state.activeLocales = locales;
    },
    SET_TAGS(state, tags) {
      state.tags = tags;
    },
    SET_PERSPECTIVES_VISIBLE(state, visible) {
      state.perspectivesVisible = visible;
    },
    SET_LAYERING_SETTINGS(state, layeringSettings) {
      state.layeringSettings = layeringSettings;
    },
    SET_LOCALIZATION_STATISTICS(state, localizationStatistics) {
      state.localizationStatistics = localizationStatistics;
    },
    RESET_MODEL(state) {
      state.model = {};
    },
    SET_LOCALE(state, locale) {
      state.options.locale = locale;
    },
    SET_USER(state, user) {
      state.user = user;

      if (user.permissions) {
        const userPerms = new PermissionList();
        userPerms.parseFromStringArray(user.permissions);
        state.user.permissions = userPerms;
      }
    },
    ADD_TO_STACK(state, route) {
      state.stack.push(route);
    },
    REPLACE_STACK(state, route) {
      state.stack = [route];
    },
    EMPTY_STACK(state) {
      state.stack = [];
    },
    SLICE_STACK(state, index) {
      state.stack = state.stack.slice(0, index + 1);
    },
    REMOVE_LAST_FROM_STACK(state) {
      state.stack = state.stack.slice(0, state.stack.length - 1);
    },
    SET_LAST_STACK_ITEM_TITLE(state, title) {
      if (state.stack[state.stack.length - 1]) {
        Vue.set(state.stack, state.stack.length - 1, {
          ...state.stack[state.stack.length - 1],
          title,
        });
      }
    },
    SET_USER_PERSPECTIVE_PREFERENCES(state, perspectivePreferences) {
      state.user.perspectivePreferences = perspectivePreferences;
    },
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('SET_LOCALE', locale);
    },
    modelChanged({ commit }, { fieldId, newValue }) {
      commit('SET_MODEL_VALUE', { fieldId, newValue });
    },
    getTypeDefs({ commit }) {
      const query = {
        query: {
          typeDefinitions: {
            id: true,
            name: true,
            titleField: true,
            access: true,
            fields: {
              id: true,
              name: true,
              localized: true,
              type: true,
              required: true,
              precision: true,
              formatPattern: true,
              validations: true,
              listOf: true,
              options: {
                appearance: true,
                helpText: true,
                settings: true,
              },
            },
          },
        },
      };

      return api.sendQueryOrMutation(query).then((result) => {
        const sortedDefs = Array.isArray(result.typeDefinitions)
          ? result.typeDefinitions.sort((a, b) => {
            if (a.id < b.id) { return -1; }
            if (a.id > b.id) { return 1; }
            return 0;
          })
          : result.typeDefinitions;

        commit('SET_TYPEDEFS', sortedDefs);
        return result;
      }).catch((err) => {
        // TODO: communicate failure
        log.error(err.message);
      });
    },
    getAvailableLocales({ commit }) {
      return getLocaleSettings().then((result) => {
        log.debug(`Locale settings: ${result.locales}`);
        commit('SET_AVAILABLE_LOCALES', result.locales);
        return result.locales;
      }).catch((err) => {
        log.error(`Error while fetching locales settings. Error: ${err}`);
      });
    },
    getTags({ commit }) {
      return getTags().then((result) => {
        log.debug(`Tags: ${JSON.stringify(result)}`);
        commit('SET_TAGS', result);
        return result;
      }).catch((err) => {
        log.error(`Error while fetching tags: ${err}`);
      });
    },
    addTag({ commit }, { locale, tag }) {
      addTag(locale, tag).then((result) => {
        const tagsCopy = [...this.state.tags];
        const index = tagsCopy.findIndex(e => e.locale === locale) || [];
        tagsCopy.splice(index, 1, result);

        commit('SET_TAGS', tagsCopy);
      }).catch((err) => {
        log.error(`Error while adding tag: ${tag} to locale: ${locale}. Error: ${err}`);
      });
    },
    getPerspectivesVisibility({ commit }) {
      getBackendConfigurationByKey('PerspectiveSettings').then((result) => {
        const visible = result.configuration.visible !== 'false'; // also defaults to true
        commit('SET_PERSPECTIVES_VISIBLE', visible);
      });
    },
    getLayeringSettings({ commit }) {
      const query = {
        query: {
          layeringSettings: {
            field: true,
            layers: {
              id: true,
              order: true,
              title: true,
              layers: {
                id: true,
                order: true,
                title: true,
              },
            },
          },
        },
      };

      return api.sendQueryOrMutation(query).then((result) => {
        log.debug('Layering settings', result);
        commit('SET_LAYERING_SETTINGS', result.layeringSettings);
        return result;
      }).catch((err) => {
        // TODO: communicate failure
        log.error(err.message);
      });
    },
    getLocalizationStatistics({ commit }) {
      const query = {
        query: {
          localizationStatistics: {
            typeStatistics: {
              typeId: true,
              localizedFields: true,
              entries: true,
              statistics: {
                locale: true,
                localized: true,
                missing: true,
              },
            },
          },
        },
      };

      return api.sendQueryOrMutation(query).then((result) => {
        log.debug('Localization statistics', result);
        commit('SET_LOCALIZATION_STATISTICS', result.localizationStatistics);
        return result;
      }).catch((err) => {
        // TODO: communicate failure
        log.error(err.message);
      });
    },
    getCurrentUser({ commit }) {
      const query = {
        query: {
          currentUser: {
            _id: true,
            email: true,
            firstName: true,
            lastName: true,
            username: true,
            groups: {
              name: true,
            },
            permissions: true,
            savedSearches: {
              _id: true,
              name: true,
              keyword: true,
              type: true,
              filters: {
                filterType: true,
                id: true,
                filterSubfield: true,
                value: true,
                operation: true,
              },
            },
            perspectivePreferences: {
              columnMode: true,
              defaultSettings: {
                perspective: {
                  mainLayer: true,
                  subLayer: true,
                },
                locale: true,
              },
              localizationSettings: {
                perspective: {
                  mainLayer: true,
                  subLayer: true,
                },
                locales: true,
              },
              cdjSettings: {
                perspectives: {
                  mainLayer: true,
                  subLayer: true,
                },
                locale: true,
              },
            },
          },
        },
      };

      return api.sendQueryOrMutation(query).then((result) => {
        commit('SET_USER', result.currentUser);
        return result.currentUser;
      }).catch((err) => {
        // TODO: communicate failure
        log.error(err.message);
      });
    },
    init({ dispatch }) {
      const typeDefs = dispatch('getTypeDefs');
      const layeringSettings = dispatch('getLayeringSettings');
      const getCurrentUser = dispatch('getCurrentUser');
      const availableLocales = dispatch('getAvailableLocales');
      const tags = dispatch('getTags');
      const perspectivesVisible = dispatch('getPerspectivesVisibility');

      return Promise.all([typeDefs, layeringSettings, getCurrentUser, availableLocales, tags, perspectivesVisible]).then(() => true);
    },
    login({ dispatch }, token) {
      auth.setToken(token);
      dispatch('init');
    },
    logout({ commit }) {
      auth.removeToken();
      commit('SET_USER', {});
      router.push({ name: 'login' });
    },
    loginLocal({ dispatch }, { username, password }) {
      return new Promise((resolve, reject) => {
        authApi.loginLocal(username, password)
          .then((result) => {
            dispatch('login', result.token);
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addToStack({ commit }, route) {
      commit('ADD_TO_STACK', route);
    },
    replaceStack({ commit }, route) {
      commit('REPLACE_STACK', route);
    },
    emptyStack({ commit }) {
      commit('EMPTY_STACK');
    },
    sliceStack({ commit }, index) {
      commit('SLICE_STACK', index);
    },
    removeLastFromStack({ commit }) {
      commit('REMOVE_LAST_FROM_STACK');
    },
    updateUserPerspectivePreferences({ commit }, newPreferences) {
      updateUserPerspectivePreferences(this.state.user._id, newPreferences).then((result) => {
        commit('SET_USER_PERSPECTIVE_PREFERENCES', result.perspectivePreferences);
      });
    },

  },
});

// i18n
Vue.use(VuexI18n.plugin, store);
Vue.i18n.add('en-US', enUS);
Vue.i18n.set(store.getters.locale);


export default store;
