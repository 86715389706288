import axioslib from 'axios';

export default class AuthApi {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.axios = axioslib.create();
  }

  loginLocal(username, password) {
    const options = {
      method: 'POST',
      data: {
        username,
        password,
      },
      url: `${this.endpoint}/auth/local`,
    };
    return this.axios(options).then((result) => {
      if (result.status !== 200) {
        throw new Error(result.message);
      }
      return result.data;
    }, (err) => {
      throw err;
    });
  }
}
