<template>
  <div class="login">
    <h1>Logging in with a token...</h1>
  </div>
</template>

<script>

export default {
  props: ['token'],
  name: 'token-login',
  beforeMount() {
    this.$store.dispatch('login', this.token);
    this.$router.push({ name: 'home' });
  },
};

</script>
