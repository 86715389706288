import Permission from './Permission';

export default class PermissionList {
  constructor() {
    this._permissions = [];
  }

  get permissions() {
    return this._permissions;
  }

  set permissions(permissions) {
    this._permissions = permissions;
  }

  /**
   * Returns copy of permission list reversed
   * @returns {Permission[]} copy of permissions reversed
   */
  get permissionsReversed() {
    return this.permissions.slice(0).reverse();
  }

  /**
   * Gets lsit of values granted for specific target
   * @param {*} target target specified
   * @param {*} access read/write
   * @returns '*' if all, otherwise list of values tostring
   */
  getValuesGranted(target, access) {
    const valuesGranted = [];
    const allGranted = this.permissions.some((permission) => {
      const valueGranted = permission.getValueGranted(target, access);
      if (valueGranted) {
        valuesGranted.push(valueGranted);
      }
      return valueGranted === '*';
    });
    return allGranted ? '*' : valuesGranted.toString();
  }

  /**
   * Does permission list grant read access
   * @param {string} target target to test
   * @param {string} value value to test
   * @returns {boolean} has read access?
   */
  grantsReadOrWriteAccess(target, value) {
    return this.permissions.some(permission => permission.grantsReadOrWriteAccess(target, value));
  }

  /**
   * Does permission list grant write (and read) access
   * @param {string} target target to test
   * @param {string} value value to test
   * @returns {boolean} has write access?
   */
  grantsWriteAccess(target, value) {
    return this.permissions.some(permission => permission.grantsWriteAccess(target, value));
  }

  /**
   * Access level granted by permission list
   * @param {string} target target to test
   * @param {string} value value to test
   * @returns {string} none/read/write
   */
  getGrantedAccess(target, value) {
    let accessValue = 'none';
    this.permissions.some((permission) => {
      if (permission.grantsReadOrWriteAccess(target, value)) {
        accessValue = permission.access;
        if (accessValue === 'write') return true;
      }
      return false;
    });
    return accessValue;
  }

  /**
   * Access level granted by permission list as integer
   * @param {string} target target to test
   * @param {string} value value to test
   * @returns {number} 0 = none/ 1 = read / 2 = write
   */
  getGrantedAccessAsInt(target, value) {
    let accessValue = 0;
    this.permissions.some((permission) => {
      if (permission.grantsReadOrWriteAccess(target, value)) {
        accessValue = permission.access === 'write' ? 2 : 1;
        if (accessValue === 2) return true;
      }
      return false;
    });
    return accessValue;
  }

  /**
   * Parse permission list from array of strings
   * @param {string[]} permissionStringArray - permission string (target:value:access)
   */
  parseFromStringArray(permissionStringArray) {
    this.permissions = permissionStringArray.map((permissionString) => {
      const permSplitted = permissionString.split(':');
      return new Permission(permSplitted[0], permSplitted[1], permSplitted[2]);
    });
  }

  /**
   * Converts permission array to array of permission strings
   * @returns array of permission strings
   */
  toStringArray() {
    return this.permissions.map(permission => permission.toString());
  }

  /**
   * Add permission to permission list
   * @param {string} target target (entity, cdj, ...)
   * @param {string} value value of permission (entity id, ...)
   * @param {string} access access type (read/write)
   * @returns {Promise} promise of adding a new permission
   */
  addPermission(target, value, access) {
    return new Promise((resolve, reject) => {
      if (target && value && access) {
        const newPerm = new Permission(target, value, access);
        // if doesn't exist yet
        if (!this.existsAlready(newPerm)) {
          this.permissions.push(newPerm);
          resolve(newPerm);
        } else {
          reject(new Error('Permission exists already'));
        }
      } else {
        reject(new Error('All fields must be specified'));
      }
    });
  }

  /**
   * Find index of permission
   * @param {Permission} permissionToCheck permission to check
   * @returns {number} permission id (-1 if not found)
   */
  indexOf(permissionToCheck) {
    const index = this.permissions.findIndex(permission => permission.isCopyOf(permissionToCheck));
    return index === undefined ? -1 : index;
  }

  /**
   * Check if permission already in list
   * @param {Permission} permissionToCheck permission to check
   * @returns {number} true if permission already in list, false otherwise
   */
  existsAlready(permissionToCheck) {
    return this.indexOf(permissionToCheck) >= 0;
  }

  /**
   * Remove permission from list
   * @param {Permission} permission permission to remove
   * @returns {Promise} promise of removing permission
   */
  removePermission(permission) {
    return new Promise((resolve, reject) => {
      const index = this.indexOf(permission);
      if (index >= 0) {
        this.permissions.splice(index, 1);
        resolve(true);
      } else {
        reject(new Error('Permission not found'));
      }
    });
  }

  /**
   * Access level granted by permission list from integer
   * @param {number} grantedAccessAsInt target to test
   * @returns {string} none/read/write
   */
  static parseGrantedAccessFromInt(grantedAccessAsInt) {
    switch (grantedAccessAsInt) {
      case 1: return 'read';
      case 2: return 'write';
      default: return 'none';
    }
  }
}
