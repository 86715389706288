export default class Permission {
  constructor(target, value, access) {
    this._target = target;
    this._value = value;
    this._access = access;
  }

  get target() {
    return this._target;
  }

  get value() {
    return this._value;
  }

  get access() {
    return this._access;
  }

  set target(value) {
    this._target = value;
  }

  set value(value) {
    this._value = value;
  }

  set access(value) {
    this._access = value;
  }

  isCopyOf(permission) {
    return permission.target === this.target
      && permission.value === this.value
      && permission.access === this.access;
  }

  toString() {
    return `${this.target}:${this.value}:${this.access}`;
  }

  getValueGranted(target, access) {
    if (access === 'read') return this.target === target ? this.value : null;
    return this.target === target && this.access === access ? this.value : null;
  }

  grantsReadOrWriteAccess(target, value) {
    return this.target === target
      && (this.value === '*' || this.value === value);
  }

  grantsWriteAccess(target, value) {
    return this.grantsReadOrWriteAccess(target, value)
      && this.access === 'write';
  }
}
