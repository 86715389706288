import Vue from 'vue';
import { setGlobalDateMasks } from 'fecha';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vuelidate from 'vuelidate';

import VueTransmit from 'vue-transmit';
import App from './App.vue';
import router from './router';
import store from './store';
import config from './config';
import './styles/main.scss';


Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueTransmit);

// FA
library.add(config.icons);
Vue.component('font-awesome-icon', FontAwesomeIcon);

setGlobalDateMasks({
  default: 'DD.MM.YYYY, HH:mm:ss'
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
