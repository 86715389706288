<template>
  <div>
    <router-link
      v-for="(stackitem, i) in stack"
      :key="i"
      :class="`stack-item w-100${stack.length === i + 1 ? ' font-weight-bold' : ''}`"
      :to="stackitem.fullPath"
      @click.native="stackItemClicked(i)"
    >
      {{ getTitle(stackitem) }}
    </router-link>
  </div>
</template>

<script>

import log from '@/browserlog';

export default {
  name: 'Stack',
  props: ['stack'],
  methods: {
    stackItemClicked(i) {
      this.$emit('item-clicked', i);
    },
    getTitle(to) {
      const getFiltersText = (route) => {
        let text = '';
        try {
          const filters = (route.query?.filters) ? JSON.parse(route.query?.filters) : '';
          if (filters.length > 0) {
            text = ` - Filters count: ${filters.length}`;
          }
        } catch (e) {
          log.error(`Error while parsing filters JSON. ${e}`);
        }

        return text;
      };

      /* eslint-disable indent */
      if (to.meta && to.meta.title) {
        return `${to.meta.title}
          ${ // if type specified add it to stack item title
            to.params.type ? ` (${to.params.type})` : ''
          }${ // if title specified add it to stack item title (add id if not)
            to.title ? ` - ${to.title}` : `${to.params.id ? ` - ${to.params.id}` : ''}`
          }${ // if locale specified add it to stack item title
            to.params.locale ? ` - ${to.params.locale}` : ''
          }${ // if mainLayer specified add it to stack item title
            to.params.mainLayer && to.params.mainLayer !== '-' ? ` - ${to.params.mainLayer}` : ''
          }${ // if subLayer specified add it to stack item title
            to.params.subLayer && to.params.subLayer !== '-' ? `/${to.params.subLayer}` : ''
          }
          ${ // if search has selected type
            to.query?.selectedType ? ` - Type: ${to.query?.selectedType}` : ''
          }
          ${ // if search has keyword param
            to.query?.keyword ? ` - Keyword: ${to.query?.keyword}` : ''
          }
          ${ // if search has filters, show count
            getFiltersText(to)
          }`;
      }
      return 'Untitled';
    },
  },
};
</script>
