import { mapGetters } from 'vuex';
import config from '@/config';

export default {
  computed: {
    ...mapGetters(['userPerspectivePreferences', 'perspectivesVisible']),
    defaultLocale() {
      return config.defaultOptions.locale;
    },
  },
  methods: {
    updateUserPreferences(params) {
      // always merge existing settings with new data
      const newPreferences = {
        ...this.userPerspectivePreferences,
        ...params,
      };

      this.$store.dispatch('updateUserPerspectivePreferences', newPreferences);
    },
    changeColumnMode(mode) {
      // save mode to user preferences
      this.updateUserPreferences({ columnMode: mode });

      // redirect to selected view
      this.toUserPreferredView(this.id, this.type, mode);
    },
    toDefaultEditView(id, type, locale) {
      this.$router.push({ name: 'edit', params: { id, type, locale, mainLayer: '-', subLayer: '-' } });
    },
    toUserPreferredView(id, type, mode) {
      // 1. Use mode parameter if given
      // 2. Try to get saved preference
      // 3. Default to 'None'
      // 4. Note: different handling if CDJ-perspective is hidden (configurable from admin panel)

      const columnMode = mode || this.userPerspectivePreferences?.columnMode || 'None';

      if (this.perspectivesVisible) { // if perspective modes enabled
        if (columnMode === 'Localization') {
          // can be multiple locales but one perspective
          const localizationSettingsPerspective = this.userPerspectivePreferences?.localizationSettings?.perspective || { mainLayer: '-', subLayer: '-' };

          this.$router.push({ name: 'edit-locales', params: { id, type, mainLayer: localizationSettingsPerspective.mainLayer, subLayer: localizationSettingsPerspective.subLayer } });
        } else if (columnMode === 'CDJ') {
          const cdjSettingsLocale = this.userPerspectivePreferences?.cdjSettings?.locale || this.defaultLocale;

          // can be multiple perspectives but one locale
          this.$router.push({ name: 'edit-persp', params: { id, type, locale: cdjSettingsLocale } });
        } else {
          // one locale and one perspective
          const defaultSettings = this.userPerspectivePreferences?.defaultSettings || { locale: this.defaultLocale, perspective: { mainLayer: '-', subLayer: '-' } };

          this.$router.push({ name: 'edit', params: { id, type, locale: defaultSettings.locale, mainLayer: defaultSettings.perspective.mainLayer, subLayer: defaultSettings.perspective.subLayer } });
        }
      } else if (columnMode === 'Localization') {
        this.$router.push({ name: 'edit-locales', params: { id, type, mainLayer: '-', subLayer: '-' } });
      } else {
        const defaultLocale = this.userPerspectivePreferences?.defaultSettings?.locale || this.defaultLocale;
        this.$router.push({ name: 'edit', params: { id, type, locale: defaultLocale, mainLayer: '-', subLayer: '-' } });
      }
    },
  },
};
