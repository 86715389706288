<template>
  <div class="entity-list">
    <div :class="spinnerWrapperClass" v-show="loading">
        <div class="spinner-content-wrapper">
          <spinner class="spinner"/>
          <slot name="cancel-action"></slot>
        </div>
    </div>
    <b-table show-empty fixed stacked="lg" :hover="hover" :fields="fields" :items="entityList.entities" :sort-by="sortBy" @row-clicked="editRow">
      <template v-slot:cell(assets.image)="row">
        <popover-image v-if="row.item.assets && row.item.assets.image && row.item.assets.image.url"
          :id="`${row.item.assets.image.id}-${row.index}`" :baseUrl="`${row.item.assets.image.url}`" width="500" height="500" />
      </template>
      <template v-slot:cell(actions)="row">
        <slot name="actions" v-bind:row="row"/>
      </template>
    </b-table>
    <b-row>
      <b-col md="12" class="my-1 text-center" v-if="displayPagination && entityList.entities.length > 0">
        <div class="col-12">
          <b-btn @click="pageChange" class="w-100">{{ $t('search.loadMore') }} (+{{ getLoadMoreAmount() }})</b-btn>
        </div>
        <!-- Load all, let's not load all if type 'any' is selected, as this would take forever -->
        <span class="p-1" v-if="entityList.type !== 'any'">
          <a @click="loadAll" class="load-all">{{ $t('search.loadAll') }} ({{ entityList.options.total }}) <span v-if="entityList.options.total > 500">({{ $t('search.lotsOfResults') }})</span> </a>
        </span>
      </b-col>
      <!-- Do not show "all loaded" if only one page of results -->
      <b-col v-else-if="!hideNoMoreResults" md="12" class="mb-5 mt-2 text-center">
        <span class="load-all">{{ $t('search.allLoaded') }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import PopoverImage from '@/components/PopoverImage.vue';

export default {
  name: 'EntityList',
  components: { Spinner, PopoverImage },
  props: {
    entityList: {
      required: true,
    },
    fields: {
      required: true,
    },
    loading: {
      required: false,
    },
    sortBy: {
      default: null,
    },
    hover: {
      default: false,
    },
    spinnerWrapperClass: {
      default: 'loading-wrapper',
    },
    // Option to hide "no more results" -text, used on Dashboard
    hideNoMoreResults: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      displayPagination: true,
      allEntitiesLoaded: false,
    };
  },
  watch: {
    entityList() {
      this.allEntitiesLoadedCheck();
    },
  },
  methods: {
    mounted() {
      this.allEntitiesLoadedCheck();
    },
    allEntitiesLoadedCheck() {
      if (this.entityList.options.total === this.entityList.entities.length) {
        // Hide load more results if all entities are visible
        this.displayPagination = false;
        this.allEntitiesLoaded = true;
      } else {
        this.displayPagination = true;
      }
    },
    pageChange() {
      this.currentPage += 1;
      this.$emit('pageChange', this.currentPage);
    },
    loadAll() {
      this.$emit('loadAll');
    },
    resetPagination() {
      this.currentPage = 1;
    },
    editRow(item) {
      this.$emit('editRow', item);
    },
    setPaginationToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    getLoadMoreAmount() {
      return this.entityList.type === 'product' ? 10 : 25;
    },
  },
};
</script>

<style lang="scss">
.entity-list {
  .load-all {
    font-size: 12px;
    cursor: pointer;
  }
}
</style>
