<template>
  <div>
    <b-progress :animated="animated" variant="success" :value="processedAmount" :max="maxAmount" height="2rem" width="10rem" />
    <div class="progress-text">{{ downloadBarText }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    processedAmount: {
      type: Number,
      default: null
    },
    maxAmount: {
      type: Number,
      default: null
    },
    animated: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    downloadBarText() {
      return (!isNaN(this.processedAmount) && !isNaN(this.maxAmount)) ? `${this.processedAmount} / ${this.maxAmount}` : "";
    }
  }
};
</script>
