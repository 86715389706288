<template>
  <div class="image-thumbnail-wrapper">
    <b-img :src="imageSource" :id="id" :class="`image-thumbnail ${extraClasses}`" :alt="altText"/>
    <b-popover :target="id" triggers="hover" :placement="placement">
      <img :src="imageSource" class="image-hover-preview" />
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'PopoverImage',

  props: {
    data: {},
    id: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    placement: {
      type: String,
      required: false,
      default: 'topleft',
    },
    extraClasses: {
      type: String,
      required: false,
    },
    altText: {
      type: String,
      required: false,
    },
  },

  computed: {
    widthParam() {
      return (this.width) ? `w=${this.width}` : '';
    },
    heightParam() {
      return (this.height) ? `h=${this.height}` : '';
    },
    imageSource() {
      let sourceUrl = `${this.baseUrl}`;

      if (!!this.width && !!this.height) {
        sourceUrl += `?w=${this.width}&h=${this.height}`;
      } else if (this.width) {
        sourceUrl += `?w=${this.width}`;
      } else if (this.height) {
        sourceUrl += `?h=${this.height}`;
      }

      return sourceUrl;
    },
  },
};
</script>
