<template>
  <div class="home">
    <h1>Dashboard</h1>
    <div class="dashboard-box">
      <div class="mt-1 mb-3 dashboard-box-title">{{ $t('home.latestApprovals') }}</div>
      <entity-list
              :entityList="approvalPendingEntities"
              :fields="approvalColumns"
              :loading="loadingApprovals"
              :hover="true"
              :hide-no-more-results="true"
              @pageChange="loadApprovalEntities"
              @editRow="toEdit">
        <template slot="actions" slot-scope="row">
          <slot name="actions" v-bind:row="row.row">
            <b-button v-if="getApprovalStatus(row.row.item) != 'Approved'" size="sm" @click.stop="toReview(row.row.item)">
              {{ $t('button.review') }}
            </b-button>
            <b-button v-else size="sm" @click.stop="toEdit(row.row.item)">
              {{ $t('button.view') }}
            </b-button>

          </slot>
        </template>
      </entity-list>
    </div>
    <div class="dashboard-box">
      <div class="mt-1 mb-3 dashboard-box-title">{{ $t('home.latestChangedEntities') }}</div>
      <entity-list
          :entityList="latestChangedEntities"
          :fields="entityChangedColumns"
          :loading="loadingChanged"
          :hover="true"
          @pageChange="loadChangedEntities"
          @editRow="toEdit">
        <template slot="actions" slot-scope="row">
          <slot name="actions" v-bind:row="row.row">
            <b-button size="sm" @click.stop="toEdit(row.row.item)">
              {{ $t('button.edit') }}
            </b-button>
          </slot>
        </template>
      </entity-list>
    </div>

    <div class="dashboard-box">
      <div class="mt-1 mb-3 dashboard-box-title">{{ $t('home.localizationStatistics') }}</div>
      <entity-list
          :entityList="localizationStatisticsWrapper"
          :fields="localizationStatisticsColumns"
          :loading="loadingLocalizationStatistics"
          :hide-no-more-results="true"
          sort-by="typeId">
      </entity-list>
    </div>
  </div>
</template>

<script>
import EntityList from '@/components/EntityList.vue';
import { searchEntities } from '@/api/apolloQueries';
import fecha from 'fecha';
import { mapState, mapActions } from 'vuex';
import config from '@/config';
import PerspectiveMixin from './PerspectiveMixin';

export default {
  name: 'home',
  components: { EntityList },
  mixins: [PerspectiveMixin],
  data() {
    return {
      approvalPendingEntities: {
        type: null,
        entities: [],
        options: {
          limit: 10,
          total: 0,
        },
      },
      latestChangedEntities: {
        type: null,
        entities: [],
        options: {
          limit: 10,
          total: 0,
        },
      },
      loadingApprovals: false,
      loadingChanged: false,
      loadingLocalizationStatistics: false,
    };
  },
  computed: {
    ...mapState(['options', 'localizationStatistics']),
    ...mapActions(['getLocalizationStatistics']),
    locale() {
      return this.options.locale;
    },
    defaultLocale() {
      return config.defaultOptions.locale;
    },
    approvalColumns() {
      return [
        {
          key: `title.${this.locale}`,
          label: this.$t('entity.title'),
        },
        {
          key: 'systemStatus.status',
          label: this.$t('entity.status'),
          formatter: this.getStatusString,
        },
        {
          key: 'systemStatus.updatedAt',
          label: this.$t('entity.updatedAt'),
          formatter: this.formatDate,
        },
        {
          key: 'systemStatus.approval.status',
          label: this.$t('approval.statusTitle'),
        },
        {
          key: 'systemStatus.approval',
          label: this.$t('approval.request.messageTitle'),
          formatter: this.formatApprovalRequestMessage,
        },
        {
          key: 'actions',
          label: this.$t('search.actions'),
        },
      ];
    },
    entityChangedColumns() {
      return [
        {
          key: `title.${this.locale}`,
          label: this.$t('entity.title'),
        },
        {
          key: 'type.id',
          label: this.$t('entity.type'),
        },
        {
          key: 'systemStatus.status',
          label: this.$t('entity.status'),
          formatter: this.getStatusString,
        },
        {
          key: 'systemStatus.updatedAt',
          label: this.$t('entity.updatedAt'),
          formatter: this.formatDate,
        },
        {
          key: 'actions',
          label: this.$t('search.actions'),
        },
      ];
    },
    localizationStatisticsColumns() {
      return [
        {
          key: 'typeId',
          label: this.$t('localizationStatistics.typeId'),
          sortable: true,
        },
        {
          key: 'localizedFields',
          label: this.$t('localizationStatistics.localizedFields'),
          sortable: true,
        },
        {
          key: 'entries',
          label: this.$t('localizationStatistics.entries'),
          sortable: true,
        },
        {
          key: 'statistics',
          label: this.$t('localizationStatistics.localeStatistics'),
          formatter: this.formatLocaleStatistics,
        },
      ];
    },
    localizationStatisticsWrapper() {
      const typeStatistics = this.localizationStatistics.typeStatistics || [];
      const wrappedStatistics = {
        entities: typeStatistics,
        options: {
          total: typeStatistics.length,
          limit: typeStatistics.length,
        },
      };
      return wrappedStatistics;
    },
  },
  methods: {
    formatDate(datestring) {
      return fecha.format(Date.parse(datestring), 'default');
    },
    getStatusString(status) {
      return this.$t(`entityStatus.${status.toLowerCase()}`);
    },
    formatApprovalRequestMessage(approval) {
      if (approval.requests && approval.requests.length > 0) {
        const last = approval.requests[approval.requests.length - 1];
        return last.message;
      }
      return approval;
    },
    formatLocaleStatistics(locales) {
      const defaultLocaleFieldValues = locales.find(locale => locale.locale === this.defaultLocale).localized;
      const statistics = locales.map((locale) => {
        if (!defaultLocaleFieldValues || !locale.localized) {
          return `${locale.locale}: 0%`;
        }
        return `${locale.locale}: ${Math.round((locale.localized / defaultLocaleFieldValues) * 100)}%`;
      });
      return statistics.join(', ');
    },
    toReview(item) {
      // No user preferences for "publish approval" view
      this.$router.push({ name: 'publish-approval', params: { type: item.type.id, id: item.id, locale: this.options.locale, mainLayer: '-', subLayer: '-' } });
    },
    toEdit(item) {
      this.toUserPreferredView(item.id, item.type.id);
    },
    getApprovalStatus(item) {
      if (item.systemStatus && item.systemStatus.approval) {
        return item.systemStatus.approval.status;
      }
      return '';
    },
    loadApprovalEntities(pageNumber = 1) {
      this.loadingApprovals = true;
      const skip = this.approvalPendingEntities.options.limit * (pageNumber - 1);

      searchEntities({
        skip,
        limit: this.approvalPendingEntities.options.limit,
        additionalFilters: [
          {
            value: 'Pending,Declined,Approved',
            operation: 'In',
            filterType: 'systemStatus',
            id: 'approval',
            filterSubfield: 'status',
          },
        ],
      }).then((results) => {
        this.loadingApprovals = false;
        this.approvalPendingEntities = results;
      }).catch(() => {
        this.loadingApprovals = false;
      });
    },
    loadChangedEntities(pageNumber = 1) {
      this.loadingChanged = true;
      const limit = this.latestChangedEntities.options.limit * pageNumber;

      searchEntities({
        limit,
        order: {
          reverseOrderBy: 'systemStatus.updatedAt',
        },
      }, pageNumber).then((results) => {
        this.latestChangedEntities = results;
        this.loadingChanged = false;
      }).catch(() => {
        this.loadingChanged = false;
      });
    },
    loadLocalizationStatistics() {
      this.loadingLocalizationStatistics = true;

      this.$store.dispatch('getLocalizationStatistics').then(() => {
        this.loadingLocalizationStatistics = false;
      }).catch(() => {
        this.loadingLocalizationStatistics = false;
      });
    },
  },

  beforeMount() {
    this.loadApprovalEntities();
    this.loadChangedEntities();
    this.loadLocalizationStatistics();
  },
};
</script>
