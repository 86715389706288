<template>
  <b-modal ref="importAssets" :title="$t('assets.import.title')" size="lg" :ok-title="$t('assets.import.buttons.ok')" :cancel-title="$t('assets.import.buttons.cancel')" :ok-disabled="!readyToUpload" @ok="addAssets" @hide="handleHide" lazy>
    <div class="loading-wrapper-modal left-0" v-show="loading">
        <div class="spinner-content-wrapper" v-show="loading">
          <spinner class="spinner"/>
          <progress-bar :processedAmount="processedAmount" :maxAmount="processableAmount" />
        </div>

        <!-- Results modal -->
        <b-modal ref="importAssetsResult" :title="$t('assets.import.results.title')" size="lg" @hide="handleResultsHide">
          <div>{{ $t('assets.import.results.success', { successCount: this.successCount }) }}</div>
          <div>{{ $t('assets.import.results.failed', { errorCount: this.errorCount }) }}</div>
          <template #modal-footer="{ hide }">
            <b-button size="sm" @click="hide()">
              {{ $t('assets.import.results.buttons.letMeCheck') }}
            </b-button>
            <b-button v-if="errorCount > 0" size="sm" variant="primary" @click="tryUploadAgainForFailedAssets()">
              {{ $t('assets.import.results.buttons.tryAgain') }}
            </b-button>
            <b-button v-if="errorCount === 0" size="sm" variant="primary" @click="closeAllModals()">
              {{ $t('assets.import.results.buttons.ok') }}
            </b-button>
          </template>
        </b-modal>
    </div>

    <!-- Actual 'Import assets' modal content -->
    <div>
      <b-row>
        <b-col cols="12">
          <div>
            <b-form-group label="Type:">
              <b-form-select v-model="selectedType" :options="availableTypes" class="mb-3" />
            </b-form-group>
          </div>
          <div>
            <b-dropdown class="full-width-dropdown mt-3">
              <template slot="button-content">
                {{ $t('assets.import.commonFields') }}
              </template>
              <b-dropdown-item v-for="(field) in availableCommonFields" :key="field.id" @click="selectField(field)">{{ field.name[defaultLocale] }}</b-dropdown-item>
            </b-dropdown>

              <b-input-group v-for="(field, i) in selectedCommonFields" :key="i" class="my-2">

                <label :for="field.id" class="p-2 w-25">
                  <span>{{ field.name[defaultLocale] }}</span>
                  <span v-if="field.options.helpText && field.options.helpText.length > 0" class="p-1">
                    <font-awesome-icon icon="question-circle" v-b-tooltip.hover :title="field.options.helpText"/>
                  </span>
                </label>


                <div v-if="getFieldType(field) === 'StringField'" class="flex-fill">
                  <b-input :id="field.id" v-model="field.value" variant="info" class="bg-info-light"></b-input>
                </div>

                <div v-else-if="getFieldType(field) === 'TextListField'" class="flex-fill">
                  <b-input-group class="mb-3">
                    <div v-if="!!field.value && field.value.length === 0">
                      {{ $t('assets.import.textListField.emptyList') }}
                    </div>
                    <b-list-group class="flex-fill">
                      <draggable :list="field.value">
                        <transition-group>
                          <b-list-group-item v-for="(text, index) in field.value" :key="`key-${index}`" class="justify-content-between align-items-center margin-left draggable">
                            {{ text }}
                            <b-badge variant="light" href="#" @click="removeListItem(field, index)" class="float-right">
                              <font-awesome-icon icon="times"></font-awesome-icon>
                            </b-badge>
                            <div class="clearfix"></div>
                          </b-list-group-item>
                        </transition-group>
                      </draggable>
                    </b-list-group>
                  </b-input-group>
                    <typeahead
                      v-model="field.typedValue"
                      size="sm"
                      :data="tagsForDefaultLocale"
                      :placeholder="$t('field.enterText')"
                      :minMatchingChars="1"
                      :keepFocus="true"
                      @keyup.enter.native="addListItem(field)"
                      @hit="addListItem(field)">

                      <template slot="append">
                        <b-btn variant="dark" class="mb-0" @click="removeField(i)"><font-awesome-icon icon="times"/></b-btn>
                      </template>

                      <template slot="suggestion" slot-scope="{ data, htmlText }">
                        <span class="hint">
                          <font-awesome-icon icon="plus"/> {{ $t('field.selectValue') }}:
                        </span>
                        <span v-html="htmlText"></span>
                      </template>
                    </typeahead>
                </div>

                <div v-else-if="getFieldType(field) === 'SelectField'" class="flex-fill">
                  <b-form-select :id="field.id" v-model="field.value" :options="getSelectOptions(field)"></b-form-select>
                </div>

                <b-input-group-append v-if="getFieldType(field) !== 'TextListField'"> <!-- remove button is handled differently in TextListField... -->
                  <b-btn variant="dark" @click="removeField(i)"><font-awesome-icon icon="times"/></b-btn>
                </b-input-group-append>

              </b-input-group>

          </div>
          <div>
            <b-form-group :label="$t('assets.import.referenceField')">
              <b-form-select v-model="selectedReferenceField" :options="availableReferenceFields" class="mb-3" />
            </b-form-group>
          </div>
          <vue-transmit class="col-12"
                        tag="section"
                        v-bind="options"
                        upload-area-classes="bg-faded"
                        ref="uploader"
                        @error="onError"
                        @timeout="onError"
                        @success="onSuccess"
                        @queue-complete="checkReadyToUploadStatus">
            <div class="d-flex align-items-center justify-content-center w-100"
                style="height:50vh; border-radius: 1rem;">
              <b-button class="btn btn-primary"
                      @click.prevent="triggerBrowse">Upload Files</b-button>
            </div>

            <template slot="files" slot-scope="props">
              <div>
                <div v-for="(file, i) in props.files" :key="file.id" :class="{'pt-5': true, 'border-top': i !== 0}">
                  <div class="media">
                    <img :src="file.dataUrl" class="img-fluid d-flex mr-3" alt="Preview not available">
                    <div class="media-body">
                      <h4 class="d-inline-block">{{ file.name }}</h4>
                      <div class="d-inline-block float-right">
                        <b-btn @click="removeAsset(file)" class="bg-danger border-danger text-white">Remove</b-btn>
                      </div>
                      <div class="progress" style="width: 100%">
                        <div class="progress-bar bg-success"
                            :style="{width: file.upload.progress + '%'}"></div>
                      </div>
                      <b-row class="mt-3">
                        <b-col cols="3">
                          <span class="field-label">{{ $t('assets.import.assetName') }}</span>
                        </b-col>
                        <b-col cols="9">
                          <b-form-group>
                            <b-form-input v-model="file.name"></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col cols="3">
                          <span class="field-label">{{ $t('assets.import.assetDescription') }}</span>
                        </b-col>
                        <b-col cols="9">
                          <b-form-group>
                            <b-form-textarea rows="4" v-model="file.description"></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="my-3">
                        <b-col>
                          <span class="field-label">{{ `${$t('assets.import.status.title')} ${file.customStatus || 'Pending'}` }}</span>
                        </b-col>
                      </b-row>
                      <b-row class="my-3" v-show="file.isSuccess">
                        <b-col>
                          <div class="bg-success text-white p-2 field-label">{{ $t('assets.import.status.success') }}</div>
                        </b-col>
                      </b-row>
                      <b-row class="my-3" v-show="!!file.customError && !file.isSuccess">
                        <b-col>
                          <div class="bg-danger text-white p-2 field-label">{{ `${$t('assets.import.status.errorTitle')} ${file.customError}` }}</div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </vue-transmit>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import config from '@/config';
import Typeahead from '@/components/typeahead/Typeahead.vue';
import { mapGetters } from 'vuex';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import ProgressBar from '@/components/ProgressBar';
import draggable from 'vuedraggable';
import auth from '@/auth';
import { changeFieldValue, uploadAsset, addEntityWithFields } from '@/api/apolloQueries';
import log from '../browserlog';

export default {
  name: 'ImportAssets',
  props: ['typeDefs', 'entitiesAddedCallback', 'typeRestrictions', 'maxFiles'],
  components: { Spinner, draggable, Typeahead, ProgressBar },
  data() {
    return {
      preventClosing: false,
      options: {
        acceptedFileTypes: [],
        clickable: false,
        accept: this.accept,
        url: '/upload',
        uploadMultiple: false,
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
        maxFiles: this.maxFiles,
      },
      multipleUploaded: false,
      loading: false,
      disableButton: false,
      selectedType: null,
      selectedReferenceField: null,
      selectedCommonFields: [],
      readyToUpload: false,
      processedAmount: 0,
      processableAmount: 0,
      successCount: 0,
      errorCount: 0,
    };
  },
  watch: {
    selectedType() {
      this.selectedReferenceField = this.availableReferenceFields[0]?.value;
      this.selectedCommonFields = []; // reset common fields
    },
    selectedReferenceField(newFieldId) {
      // check accepted filetypes when reference field is changed

      let validations;
      const field = this.selectedTypeDef.fields.find(innerField => innerField.id === newFieldId);

      if (field.type === 'AssetReferenceField') {
        validations = field?.validations.find(validation => validation.__typename === 'AssetReferenceMediaTypeRule')?.mediaTypes;
      } else if (field.type === 'ListField' && field.listOf === 'Asset') {
        validations = field?.validations
          .find(validation => validation.__typename === 'AssetReferenceListItemRule' && validation.rule.__typename === 'AssetReferenceMediaTypeRule')?.rule?.mediaTypes;
      }

      const mapMimeTypes = (mediaType) => {
        switch (mediaType) {
          case 'image':
            return 'image/*';

          case 'pdfdocument':
            return 'application/pdf';

          case 'audio':
            return 'audio/*';

          case 'video':
            return 'video/*';

          default:
            return mediaType;
        }
      };

      this.options.acceptedFileTypes = (validations || []).map(mapMimeTypes);
    },
  },
  computed: {
    ...mapGetters(['tags']),
    defaultLocale() {
      return config.defaultOptions.locale;
    },
    importableAssetContentTypes() {
      return config.importableAssetContentTypes;
    },
    availableTypes() {
      const filterAllowedTypes = type => ((!!this.typeRestrictions && this.typeRestrictions.length > 0) ? this.typeRestrictions.includes(type.id) : true);

      return this.typeDefs
        .filter(filterAllowedTypes)
        .filter(type => type.fields.some(this.isAssetReferenceField))
        .filter(type => this.importableAssetContentTypes.includes(type.id))
        .reduce((result, typeDef) => {
          if (typeDef.access === 'write') {
            result.push({
              value: typeDef.id,
              text: typeDef.name[this.defaultLocale],
            });
          }
          return result;
        }, []);
    },
    selectedTypeDef() {
      return this.typeDefs.find(type => type.id === this.selectedType);
    },
    availableReferenceFields() {
      let availableFields = [];
      if (this.selectedTypeDef) {
        availableFields = this.selectedTypeDef.fields.filter(this.isAssetReferenceField).map(field => ({ value: field.id, text: field.name[this.defaultLocale] }));
      }

      return availableFields;
    },
    availableCommonFields() {
      let availableFields = [];
      if (this.selectedTypeDef) {
        availableFields = this.selectedTypeDef.fields
          .filter(field => this.isNotReferenceField(field) && this.isNotFieldSelected(field) && this.isNotLayerField(field) && this.isNotTitleField(this.selectedTypeDef, field));
      }

      return availableFields;
    },
    tagsForDefaultLocale() {
      return this.tags.find(e => e.locale === this.defaultLocale)?.values || [];
    },
  },
  methods: {
    checkReadyToUploadStatus() {
      this.readyToUpload = this.$refs.uploader.files.length > 0 && this.$refs.uploader.files.some(file => !file.isSuccess);
    },
    getFieldDefFromSelectedType(fieldId) {
      return this.selectedTypeDef.fields.find(field => field.id === fieldId);
    },
    show() {
      // set default selection if not already initialized
      if (!this.selectedType) {
        this.selectedType = (!!this.availableTypes && this.availableTypes.length > 0) ? this.availableTypes[0].value : null;
      }

      this.$refs.importAssets.show();
    },
    hide() {
      this.$refs.importAssets.hide();
    },
    triggerBrowse() {
      this.$refs.uploader.triggerBrowseFiles();
    },
    onError(file, errorMsg) {
      file.customError = errorMsg;
    },
    onSuccess(VTransmitFile, response) {
      if (response && response.path) {
        this.disableButton = false;
        VTransmitFile.fileUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/${response.path}`;
        VTransmitFile.filename = response.originalname;

        const fullName = VTransmitFile.name;
        if (fullName.includes('.')) {
          // assume name contains file extension, remove it
          const nameWithoutExtension = fullName.slice(0, fullName.lastIndexOf('.'));
          if (nameWithoutExtension && nameWithoutExtension.length > 0) {
            VTransmitFile.name = nameWithoutExtension;
          }
        }

        VTransmitFile.customStatus = this.$t('assets.import.status.ready');
      }
    },
    selectField(field) {
      this.selectedCommonFields.push({
        ...field,
        value: (field.type === 'ListField') ? [] : '', // extend field data with value
      });
    },
    removeField(index) {
      this.selectedCommonFields.splice(index, 1);
    },
    handleHide(bvModalEvt) {
      if (this.preventClosing) {
        bvModalEvt.preventDefault();
      } else {
        // reset on hide
        this.selectedReferenceField = this.availableReferenceFields[0]?.value;
        this.selectedCommonFields = [];
      }
    },
    closeAllModals() {
      this.$refs.importAssetsResult.hide();
      this.$refs.importAssets.hide();
    },
    tryUploadAgainForFailedAssets() {
      this.$refs.importAssetsResult.hide();
      this.addAssets()
    },
    handleResultsHide() {
      // Reset
      this.errorCount = 0;
      this.successCount = 0;
      this.checkReadyToUploadStatus();
    },
    addAssets(bvModalEvt) {
      if (!!bvModalEvt) {
        bvModalEvt.preventDefault();
      }

      if (this.loading) {
        log.info('Loading in process already.');
        return;
      }

      // Upload only files that are not already successfully processed
      // TODO: maybe button to remove already successfull uploads?
      const files = this.$refs.uploader.files.filter(file => !file.isSuccess);

      if (files.length > 0) {
        this.preventClosing = true;
        this.loading = true;

        const commonFields = this.selectedCommonFields.map((field) => {
          // TODO: support for arrays?
          const value = (field.localized) ? { locale: this.defaultLocale, value: field.value } : field.value;

          return { [field.id]: value };
        });

        const addEntityWrapper = (title) => {
          const fieldParams = [...commonFields];

          const { titleField } = this.selectedTypeDef;

          if (titleField) {
            const titleFieldDef = this.getFieldDefFromSelectedType(titleField);

            if (titleFieldDef) {
              const value = (titleFieldDef.localized) ? { locale: this.defaultLocale, value: title } : title;

              fieldParams.push({ [titleFieldDef.id]: value });
            }
          }

          return addEntityWithFields(this.selectedType, fieldParams);
        };

        const linkAssetAndEntityWrapper = (assetId, entityAdded) => {
          const referenceFieldDef = this.getFieldDefFromSelectedType(this.selectedReferenceField);

          const fixedEntity = {
            ...entityAdded,
            type: {
              id: entityAdded.type, // refactor type to path type.id since changeFieldValue is built to read it from there
            },
          };

          const linkAssetAndEntityParams = {
            entity: fixedEntity,
            id: referenceFieldDef.id,
            newValue: assetId,
            mutationParameter: 'assets',
            layerPath: '',
            localized: referenceFieldDef.localized,
          };

          return changeFieldValue(linkAssetAndEntityParams).then(result =>
            ({
              ...fixedEntity,
              version: result.version, // update version
            }));
        };

        const createWorkflow = (file) => {
          const assetData = {
            title: file.name,
            filename: file.filename,
            url: file.fileUrl,
            description: file.description || '',
            type: file.type,
          };

          const addedAsset = uploadAsset(assetData);
          const addedEntity = addEntityWrapper(file.name);

          file.customStatus = this.$t('assets.import.status.uploading');
          file.customError = ''; // reset error message

          return Promise.all([addedAsset, addedEntity]).then((values) => {
            const { asset } = values[0].addAssetFromUrl;

            const assetId = asset.id;
            const title = asset.title || values[1].title;

            const newlyAddedEntity = {
              ...values[1],
              title,
            };

            return linkAssetAndEntityWrapper(assetId, newlyAddedEntity).then((res) => {
              file.customStatus = 'Done';
              file.isSuccess = true;
              return res;
            });
          }).catch((e) => {
            console.error(`Failed to add asset and entity with error: ${e}`);

            file.customStatus = this.$t('assets.import.status.error');
            file.customError = `${e}`;
            file.isSuccess = false;

            return Promise.reject(assetData);
          });
        };

        /*
        // PARALLEL LOGIC START
        // Here ALL the asset + wrapper making requests are fired at once. This however caused problems when loading tens of images at once.

        let promises = files.map(createWorkflow);

        Promise.allSettled(promises).then(results => {
          const successes = results.filter(res => res.status === 'fulfilled').map(res => res.value);
          const errorCount = results.filter(res => res.status === 'rejected').length;

          this.loading = false;
          this.preventClosing = false;

          if (this.entitiesAddedCallback) {
            this.entitiesAddedCallback(successes);
          } else if (errorCount === 0) {
            this.hide();
          }
        });

        // PARALLEL LOGIC END
        */

        // SEQUENTIAL LOGIC START
        // This is for making asset + wrapper creations sequantially, so that we won't flood backend with requests.

        const successes = [];

        this.processedAmount = 0;
        this.processableAmount = files.length;

        const firstFile = files.shift();

        const successHandler = result => {
          this.successCount += 1;
          successes.push(result);
        }

        const errorHandler = (error) => {
          this.errorCount += 1;
          console.error(error);
        };

        files.reduce((promise, file) => promise
          .then(successHandler)
          .catch(errorHandler)
          .then(() => {
            this.processedAmount += 1;
            return createWorkflow(file);
          }), createWorkflow(firstFile))
          .then(successHandler)
          .catch(errorHandler)
          .finally(() => {
            this.processedAmount += 1;

            if (this.entitiesAddedCallback) {
              // wait for one second so that it doesn't seem that processing gets interrupted when importing last image..
              setTimeout(() => {
                this.loading = false;
                this.preventClosing = false;

                this.entitiesAddedCallback(successes);
              }, 1000);
            } else {
              // Show errors in import assets
              // TODO: group errors and successes maybe?
              this.loading = false;
              this.preventClosing = false;
              this.$refs.importAssetsResult.show();
            }
          });

        // SEQUENTIAL LOGIC END
      }
    },
    removeAsset(file) {
      this.$refs.uploader.removeFile(file);
      this.checkReadyToUploadStatus(); // check if there are files to upload..
    },
    isReferenceField(field) {
      const isSingleReferenceField = field.type === 'RelationshipReferenceField' || field.type === 'AssetReferenceField';
      const isListReferenceField = field.type === 'ListField' && (field.listOf === 'Relationship' || field.listOf === 'Asset');

      return isSingleReferenceField || isListReferenceField;
    },
    isNotReferenceField(field) {
      return !this.isReferenceField(field);
    },
    isAssetReferenceField(field) {
      return field.type === 'AssetReferenceField' || (field.type === 'ListField' && field.listOf === 'Asset');
    },
    isFieldSelected(field) {
      return this.selectedCommonFields.some(selectedField => selectedField.id === field.id);
    },
    isNotFieldSelected(field) {
      return !this.isFieldSelected(field);
    },
    isLayerField(field) {
      return field.type === 'LayerField';
    },
    isNotLayerField(field) {
      return !this.isLayerField(field);
    },
    isTitleField(typeDef, field) {
      return (typeDef.titleField && typeDef.titleField === field.id);
    },
    isNotTitleField(typeDef, field) {
      return !this.isTitleField(typeDef, field);
    },
    getFieldType(fieldDefinition) {
      switch (fieldDefinition.type) {
        case 'StringField':
          switch (fieldDefinition.options ? fieldDefinition.options.appearance : '') {
            case 'dropdown':
              return 'SelectField';
            default:
              return 'StringField';
          }

        case 'IntegerField':
        case 'DecimalField':
          switch (fieldDefinition.options ? fieldDefinition.options.appearance : '') {
            case 'dropdown':
              return 'SelectField';
            default:
              return 'StringField';
          }

        case 'ListField':
          switch (fieldDefinition.listOf) {
            case 'String':
              switch (fieldDefinition.options ? fieldDefinition.options.appearance : '') {
                case 'tagEditor':
                  return 'TextListField';
                default:
                  return 'StringField';
              }
            default:
              return 'SelectField';
          }

        default:
          return 'StringField';
      }
    },
    addListItem(field) {
      if (field.typedValue) {
        const value = field.typedValue;

        if (!field.value.includes(value)) {
          field.value.push(value);
        }

        this.$store.dispatch('addTag', { locale: this.defaultLocale, tag: value });

        field.typedValue = ''; // empty input field
      }
    },
    removeListItem(field, index) {
      field.value.splice(index, 1);
    },
    getSelectOptions(field) {
      const values = field.validations
        .find(validation => validation.id === 'accepted' && validation.__typename === 'AcceptedStringValuesRule')?.acceptedStrings
        .map(str => ({ text: str, value: str })) || [];

      return [{ text: '-', value: null }].concat(values);
    },
  },
  filters: {
    json(value) {
      return JSON.stringify(value, null, 2);
    },
  },
};
</script>
