import axioslib from 'axios';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import auth from '../auth';
import log from '../browserlog';

const { CancelToken } = axioslib;

export default class GraphqlApi {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.axios = axioslib.create();
    this.axios.interceptors.request.use(
      (config) => {
        /* eslint-disable no-param-reassign */
        if (typeof window !== 'undefined' && sessionStorage) {
          config.headers.Authorization = `Bearer ${auth.getToken()}`;
        }
        /* eslint-enable no-param-reassign */
        return config;
      },
      (error) => {
        // Forward unauthenticated to login page on browser use
        if (error.status === 401 && typeof window !== 'undefined') {
          if (sessionStorage) {
            auth.removeToken();
          }
          Promise.reject(error);
          window.location = '/login';
        } else {
          Promise.reject(error);
        }
      },
    );
  }

  static checkErrors(result) {
    if (result.status !== 200) throw new Error(`Error with request, code ${result.status}`);
    if (result.data.error) throw new Error(`${result.data.error.errors[0].message}`);
    if (result.data.errors) throw new Error(`${result.data.errors[0].message}`);
  }

  sendParameterizedQueryOrMutation(parameterizedQuery, queryVariables) {
    const options = {
      method: 'POST',
      data: {
        query: parameterizedQuery,
        variables: queryVariables,
      },
      url: this.endpoint,
    };
    log.debug(`GraphqlApi: => ${this.endpoint} sending '${parameterizedQuery}' with variables '${JSON.stringify(queryVariables)}'`);

    return this.axios(options).then((result) => {
      this.constructor.checkErrors(result);
      log.debug(`GraphqlApi: result data ${JSON.stringify(result.data)}`);
      return result.data.data;
    }, (err) => {
      if (err.response) {
        log.error(`Got an error ${err.response.status} : ${JSON.stringify(err.response.data)}`);
      }
      throw err;
    });
  }

  sendQueryOrMutation(query, cancelToken) {
    const options = {
      method: 'POST',
      data: {
        query: jsonToGraphQLQuery(query),
      },
      url: this.endpoint,
      cancelToken: cancelToken ? cancelToken.token : null,
    };

    log.debug(`GraphqlApi: sending ${JSON.stringify(options)}`);
    return this.axios(options).then((result) => {
      this.constructor.checkErrors(result);
      log.debug(`GraphqlApi: result data ${JSON.stringify(result.data)}`);
      return result.data.data;
    }, (err) => {
      throw err;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getCancellationSource() {
    return CancelToken.source();
  }
}
