import { EnumType } from 'json-to-graphql-query';
import GraphqlApi from '../api/GraphqlApi';
import config from '../config';
import log from '../browserlog';

const api = new GraphqlApi(config.proxy);

const getAvailableLocales = () => {
  const query = {
    query: {
      locales: {
        locales: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.locales).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const searchAssets = ({ keyword = '', type = null, skip = 0, limit = 10 }) => {
  const parameters = [{
    operation: new EnumType('IsEqualTo'),
    field: 'any',
    value: keyword,
  }];

  if (type) {
    parameters.push({
      operation: new EnumType('Matches'),
      field: 'mediaType',
      value: type,
    });
  }

  const query = {
    query: {
      searchAssets: {
        __args: {
          searchAssets: {
            parameters,
            limit,
            skip,
          },
        },
        total: true,
        limit: true,
        skip: true,
        results: {
          id: true,
          title: true,
          url: true,
          mediaType: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => ({
    assets: result.searchAssets.results,
    options: {
      total: result.searchAssets.total,
      limit: result.searchAssets.limit,
      skip: result.searchAssets.skip,
    },
  })).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};


const searchEntities = ({ type = 'any', additionalFilters = [], keyword, skip = 0, limit = 10, order = {} }, cancelToken = null) => {
  // parameters for search
  const parameters = [];

  if (keyword) {
    parameters.push({
      operation: new EnumType('Matches'),
      field: config.typesSearchedFromAnyField.indexOf(type) < 0 ? 'title' : 'any',
      value: keyword,
    });
  }

  additionalFilters.forEach((filter) => {
    // special handling for ID search
    const fieldValue = (filter.id === 'sys.id') ? 'id' : `${filter.filterType}.${filter.id}${filter.filterSubfield ? '.' : ''}${filter.filterSubfield}`;
    parameters.push({
      operation: new EnumType(filter.operation),
      field: fieldValue,
      value: `${filter.value}`, // convert to string
    });
  });

  const query = {
    query: {
      search: {
        __args: {
          searchEntities: {
            parameters,
            limit,
            skip,
            type,
            order,
          },
        },
        total: true,
        limit: true,
        skip: true,
        results: {
          id: true,
          title: true,
          version: true,
          systemStatus: {
            status: true,
            createdAt: true,
            updatedAt: true,
            approval: {
              id: true,
              status: true,
              requests: {
                id: true,
                revision: true,
                requestType: true,
                status: true,
                requestedBy: true,
                createdDate: true,
                message: true,
                comments: {
                  message: true,
                  commentedBy: true,
                  date: true,
                },
                updatedDate: true,
                approvedBy: true,
                approvedDate: true,
              },
            },
          },
          type: {
            id: true,
            path: true,
          },
          fields: true,
          assets: true,
          relationships: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query, cancelToken).then(result => ({
    type,
    entities: result.search.results,
    options: {
      total: result.search.total,
      limit: result.search.limit,
      skip: result.search.skip,
    },
  })).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getEntity = ({ type, id, requestType = 'Management' }, cancelToken = null) => {
  const query = {
    query: {
      entity: {
        __args: {
          type,
          id,
          requestType: new EnumType(requestType),
        },
        entity: {
          id: true,
          version: true,
          title: true,
          description: true,
          type: {
            id: true,
            path: true,
          },
          fields: true,
          assets: true,
          relationships: true,
          systemStatus: {
            status: true,
            createdAt: true,
            updatedAt: true,
            approval: {
              id: true,
              requests: {
                id: true,
                revision: true,
                requestType: true,
                status: true,
                requestedBy: true,
                createdDate: true,
                message: true,
                comments: {
                  message: true,
                  commentedBy: true,
                  date: true,
                },
                updatedDate: true,
                approvedBy: true,
                approvedDate: true,
              },
            },
          },
          layers: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query, cancelToken).then(result => result.entity.entity, (err) => {
    throw err;
  }).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const publishAsset = (id) => {
  log.debug(`Publishing asset id: ${id}`);
  const query = `mutation($id: String!) {
    publishAsset(id: $id) {
      id
      version
    }
  }`;

  const variables = { id };

  return api.sendParameterizedQueryOrMutation(query, variables).then((result) => {
    log.debug(`Published asset ${result.id}`);
    return result.publishAsset;
  }).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const addEntity = (type, title, titleField) => {
  log.debug(`Adding new entity of type ${type} titled ${title}`);
  const query = `mutation($type: String!, $fields: [JSON]) {
    addEntity(type: $type, fields: $fields) {
      id
      type
      title
    }
  }`;

  const variables = {
    type,
    fields: [
      { [titleField]: title },
    ],
  };

  return api.sendParameterizedQueryOrMutation(query, variables).then((result) => {
    log.debug(`${title} added, id: ${result.addEntity.id}`);
    return result.addEntity;
  }).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const addEntityWithFields = (type, fields) => {
  log.debug(`Adding new entity of type ${type} with fields ${fields}`);
  const query = `mutation($type: String!, $fields: [JSON]) {
    addEntity(type: $type, fields: $fields) {
      id
      type
      title
      version
    }
  }`;

  const variables = {
    type,
    fields,
  };

  return api.sendParameterizedQueryOrMutation(query, variables).then((result) => {
    log.debug(`${type} added, id: ${result.addEntity.id}`);
    return result.addEntity;
  }).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const changeFieldValue = ({ entity, id, newValue, mutationParameter, layerPath, localized }) => {
  const query = `mutation($id: String!, $type: String!, $version: Int!, $${mutationParameter}: [JSON], $layer: String) {
    setFieldValues(id: $id, type: $type, version: $version, ${mutationParameter}: $${mutationParameter}, layer: $layer) {
      id
      type
      version
      wasSuccessful
    }
  }`;

  const localizedNewValue = [];
  if (localized) {
    Object.keys(newValue).map((key) => {
      localizedNewValue.push({
        locale: key,
        [`value${Array.isArray(newValue[key]) ? 's' : ''}`]: newValue[key],
      });
      return true;
    });
  }

  const variables = {
    id: entity.id,
    type: entity.type.id,
    version: entity.version,
    layer: layerPath,
    [mutationParameter]: [
      { [id]: localizedNewValue.length > 0 ? localizedNewValue : newValue },
    ],
  };

  return api.sendParameterizedQueryOrMutation(query, variables).then(result => ({
    version: result.setFieldValues.version,
  })).catch((err) => {
    throw err;
  });
};

const saveFields = ({ id, type, version, layer, fields }) => {
  const query = `mutation($id: String!, $type: String!, $version: Int!, $fields: [JSON], $layer: String) {
    setFieldValues(id: $id, type: $type, version: $version, fields: $fields, layer: $layer) {
      id
      type
      version
      wasSuccessful
    }
  }`;

  const variables = {
    id,
    type,
    version,
    layer,
    fields,
  };

  return api.sendParameterizedQueryOrMutation(query, variables).then(result => ({
    version: result.setFieldValues.version,
  })).catch((err) => {
    throw err;
  });
};

const saveEntity = ({ entity, schema }) => {
  // TODO: For now assets and relationships are left out, since they are always saved via "add" / "link" button, and the original problem related to normal "fields"
  // TODO: assets
  // TODO: relationships

  // check from schema if field is localized -> if yes, then make localized value out of field
  const buildFieldParameters = (fields, schemaFields) =>
    Object.entries(fields).map(([key, value]) => {
      const isLocalized = (schemaFields[key]) ? schemaFields[key].localized : false;
      let parameterValue;
      if (isLocalized) {
        parameterValue = Object.entries(value).map(([localeKey, localeValue]) =>
          ({
            locale: localeKey,
            [`value${Array.isArray(localeValue) ? 's' : ''}`]: localeValue,
          }));
      } else {
        parameterValue = value;
      }

      return ({ [key]: parameterValue });
    });

  const schemaFields = schema.fields.reduce((map, field) => ({ ...map, [field.id]: field }), {});

  return saveFields({ id: entity.id, type: entity.type.id, version: entity.version, layer: '', fields: buildFieldParameters(entity.fields, schemaFields) }).then(result =>
    // Save layered fields
    Object.entries(entity.layers || []).reduce(
      (promise, [layerPath, layerObj]) =>
        promise.then(innerResult => saveFields({ id: entity.id, type: entity.type.id, version: innerResult.version, layer: layerPath, fields: buildFieldParameters(layerObj.fields, schemaFields) }))
      , Promise.resolve(result),
    ))
    .catch((err) => {
      throw err;
    });
};

const setStatus = ({ entity, newStatus }) => {
  const query = `mutation($id: String!, $version: Int!, $status: EntityStatus!) {
    setStatus(id: $id, version: $version, status: $status) {
      id
      version
      status
      wasSuccessful
    }
  }`;
  const variables = {
    id: entity.id,
    version: entity.version,
    status: newStatus,
  };

  return api.sendParameterizedQueryOrMutation(query, variables).then(result => ({
    version: result.setStatus.version,
    newStatus: result.setStatus.status,
  })).catch((err) => {
    throw err;
  });
};

/**
 * List all entities of specified type with only id and name
 * TODO: Backend to provide lighter way to list
 * @param {string} type Entity type
 */
const listSimpleEntities = (type) => {
  // parameters for search
  const parameters = [];

  parameters.push({
    operation: new EnumType('IsEqualTo'),
    field: 'type.id',
    value: type,
  });

  const query = {
    query: {
      search: {
        __args: {
          searchEntities: {
            parameters,
            limit: 100, // TODO: better way to search all
            skip: 0,
            type,
          },
        },
        results: {
          id: true,
          title: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => ({
    type,
    entities: result.search.results,
  })).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const getUser = (username) => {
  const query = {
    query: {
      user: {
        __args: {
          username,
        },
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
        groups: {
          name: true,
        },
        permissions: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.user).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const listUsers = () => {
  const query = {
    query: {
      users: {
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
        groups: {
          name: true,
        },
        permissions: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.users).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const addUser = (username, email, firstName, lastName, password) => {
  const query = {
    mutation: {
      addUser: {
        __args: {
          username,
          email,
          firstName,
          lastName,
          password,
        },
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.addUser).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const updateUser = (id, username, email, firstName, lastName) => {
  const query = {
    mutation: {
      updateUser: {
        __args: {
          id,
          username,
          email,
          firstName,
          lastName,
        },
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateUser).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const updateUserPerspectivePreferences = (id, perspectivePreferences) => {
  const query = {
    mutation: {
      updateUserPerspectivePreferences: {
        __args: {
          id,
          perspectivePreferences,
        },
        _id: true,
        perspectivePreferences: {
          columnMode: true,
          defaultSettings: {
            perspective: {
              mainLayer: true,
              subLayer: true,
            },
            locale: true,
          },
          localizationSettings: {
            perspective: {
              mainLayer: true,
              subLayer: true,
            },
            locales: true,
          },
          cdjSettings: {
            perspectives: {
              mainLayer: true,
              subLayer: true,
            },
            locale: true,
          },
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateUserPerspectivePreferences).catch((err) => {
    log.error(err.message);
  });
};

const removeUser = (id) => {
  const query = {
    mutation: {
      removeUser: {
        __args: {
          id,
        },
        success: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.removeUser).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const resetPassword = (id, newPassword) => {
  const query = {
    mutation: {
      resetPassword: {
        __args: {
          id,
          newPassword,
        },
        success: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.resetPassword).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const getUserGroup = (id) => {
  const query = {
    query: {
      userGroup: {
        __args: {
          id,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.userGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const addUserGroup = (name, description, externalId, userIds, permissions) => {
  const query = {
    mutation: {
      addUserGroup: {
        __args: {
          name,
          description,
          externalId,
          userIds,
          permissions,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.addUserGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const updateUserGroupUserIds = (id, userIds) => {
  const query = {
    mutation: {
      updateUserGroupUserIds: {
        __args: {
          id,
          userIds,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateUserGroupUserIds).catch((err) => {
    log.error(err.message);
  });
};

const updateUserGroup = (id, name, description, externalId, userIds, permissions) => {
  const query = {
    mutation: {
      updateUserGroup: {
        __args: {
          id,
          name,
          description,
          externalId,
          userIds,
          permissions,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateUserGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const removeUserGroup = (id) => {
  const query = {
    mutation: {
      removeUserGroup: {
        __args: {
          id,
        },
        success: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.removeUserGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const listUserGroups = () => {
  const query = {
    query: {
      userGroups: {
        _id: true,
        name: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.userGroups).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const getTags = () => {
  const query = {
    query: {
      tags: {
        locale: true,
        values: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.tags).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getTagsByLocale = (locale) => {
  const query = {
    query: {
      tagsByLocale: {
        __args: {
          locale,
        },
        locale: true,
        values: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.tagsByLocale).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const addTag = (locale, tag) => {
  const query = {
    mutation: {
      addTag: {
        __args: {
          locale,
          tag,
        },
        locale: true,
        values: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.addTag).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getLocaleSettings = () => {
  const query = {
    query: {
      localeSettings: {
        locales: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.localeSettings).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
    throw err;
  });
};

const getBackendConfiguration = (id) => {
  const query = {
    query: {
      backendConfiguration: {
        __args: {
          id,
        },
        _id: true,
        key: true,
        configuration: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.backendConfiguration).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
    throw err;
  });
};

const getBackendConfigurationByKey = (key) => {
  const query = {
    query: {
      backendConfigurationByKey: {
        __args: {
          key,
        },
        _id: true,
        key: true,
        configuration: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.backendConfigurationByKey).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
    throw err;
  });
};

const listBackendConfigurations = () => {
  const query = {
    query: {
      backendConfigurations: {
        _id: true,
        key: true,
        configuration: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.backendConfigurations).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const addBackendConfiguration = (key, configuration) => {
  const query = {
    mutation: {
      addConfiguration: {
        __args: {
          key,
          configuration,
        },
        _id: true,
        key: true,
        configuration: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.addConfiguration).catch((err) => {
    throw err;
  });
};

const updateBackendConfiguration = (id, key, configuration) => {
  const query = {
    mutation: {
      updateConfiguration: {
        __args: {
          id,
          key,
          configuration,
        },
        _id: true,
        key: true,
        configuration: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateConfiguration).catch((err) => {
    throw err;
  });
};

const refreshBackendConfiguration = (key) => {
  const query = {
    mutation: {
      refreshConfiguration: {
        __args: {
          key,
        },
        success: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.refreshConfiguration).catch((err) => {
    throw err;
  });
};

const uploadAsset = (data) => {
  const query = `mutation ($title: [LocalizedStringInput!]!, $description: [LocalizedStringInput!], $file: [LocalizedFileFromUrlInput!]!) {
  addAssetFromUrl(title: $title, description: $description, file: $file) {
    id
    version
    asset {
      id
      mediaType
      url
      title
      description
      description
      }
   }
  }`;

  const variables = {
    title: [
      { locale: 'en-US', value: data.title },
    ],
    description: [
      { locale: 'en-US', value: data.description },
    ],
    file: [
      {
        locale: 'en-US',
        value: {
          fileName: data.title,
          mediaType: data.type,
          url: data.url,
        },
      },
    ],
  };

  return api.sendParameterizedQueryOrMutation(query, variables).then(result => result).catch((err) => {
    log.error(err.message);
  });
};

const createApprovalRequest = (data) => {
  const query = `mutation ($entityId: String!, $version: Int!, $requestType: ApprovalRequestType!, $message: String) {
        createApprovalRequest(entityId: $entityId, version: $version, requestType: $requestType, message: $message) {
          id
          version
          approval {
            id
            title
            status
            requests {
              id
              revision
              requestType
              status
              requestedBy
              createdDate
              message
              comments {
                message
                commentedBy
                date
              }
              updatedDate
              approvedBy
              approvedDate
            }
          }
        }
      }`;

  const variables = {
    entityId: data.id,
    version: data.version,
    requestType: data.requestType,
    message: data.message,
  };
  console.log('variables: ', variables);

  return api.sendParameterizedQueryOrMutation(query, variables).then(result => result.createApprovalRequest).catch((err) => {
    log.error(err.message);
  });
};

const respondToApprovalRequest = (data) => {
  const query = `mutation($entityId: String!, $id: String!, $response: ApprovalStatus!, $comment: String) {
        respondToApprovalRequest(entityId: $entityId, id: $id, response: $response, comment: $comment) {
          id
          version
          approval {
            id
            title
            status
            requests {
              id
              revision
              requestType
              status
              requestedBy
              createdDate
              message
              comments {
                message
                commentedBy
                date
              }
              updatedDate
              approvedBy
              approvedDate
            }
          }
        }
      }`;
  const variables = {
    entityId: data.entityId,
    id: data.id,
    response: data.response,
    comment: data.comment,
  };
  return api.sendParameterizedQueryOrMutation(query, variables).then(result => result.respondToApprovalRequest).catch((err) => {
    log.error(err.message);
  });
};

const updateApprovalRequest = (data) => {
  const query = `mutation ($entityId: String!, $version: Int!, $id: String!, $requestType: ApprovalRequestType!, $requestedBy: String!, $message: String) {
        updateApprovalRequest(entityId: $entityId, version: $version, id: $id, requestType: $requestType, requestedBy: $requestedBy, message: $message) {
          id
          version
          approval {
            id
            title
            status
            requests {
              id
              revision
              requestType
              status
              requestedBy
              createdDate
              message
              comments {
                message
                commentedBy
                date
              }
              updatedDate
              approvedBy
              approvedDate
            }
          }
        }
      }`;
  const variables = {
    entityId: data.entityId,
    version: data.version,
    id: data.id,
    requestType: data.requestType,
    requestedBy: data.requestedBy,
    message: data.message,
  };
  console.log('variables: ', variables);
  return api.sendParameterizedQueryOrMutation(query, variables).then(result => result.updateApprovalRequest).catch((err) => {
    log.error(err.message);
  });
};

const addSavedSearch = (name, type, keyword, filters, order) => {
  const query = {
    mutation: {
      addSavedSearch: {
        __args: {
          name,
          type,
          keyword,
          filters,
          order,
        },
        _id: true,
        name: true,
        type: true,
        keyword: true,
        filters: {
          filterType: true,
          id: true,
          filterSubfield: true,
          value: true,
          operation: true,
        },
      },
    },
  };
  return api.sendQueryOrMutation(query).then(result => result.addSavedSearch).catch((err) => {
    log.error(err.message);
  });
};

const removeSavedSearch = (id) => {
  const query = {
    mutation: {
      removeSavedSearch: {
        __args: {
          id,
        },
        success: true,
      },
    },
  };
  return api.sendQueryOrMutation(query).then(result => result.removeSavedSearch).catch((err) => {
    log.error('errorii');
    log.error(err.message);
  });
};

const getCancellationToken = () => api.getCancellationSource();

export {
  getCancellationToken,
  getAvailableLocales,
  searchAssets,
  searchEntities,
  getEntity,
  addEntity,
  addEntityWithFields,
  changeFieldValue,
  saveFields,
  saveEntity,
  setStatus,
  listSimpleEntities,
  getUser,
  listUsers,
  addUser,
  updateUser,
  updateUserPerspectivePreferences,
  resetPassword,
  removeUser,
  getUserGroup,
  addUserGroup,
  updateUserGroupUserIds,
  updateUserGroup,
  removeUserGroup,
  listUserGroups,
  getTags,
  getTagsByLocale,
  addTag,
  getLocaleSettings,
  listBackendConfigurations,
  getBackendConfiguration,
  getBackendConfigurationByKey,
  addBackendConfiguration,
  updateBackendConfiguration,
  refreshBackendConfiguration,
  uploadAsset,
  createApprovalRequest,
  updateApprovalRequest,
  respondToApprovalRequest,
  addSavedSearch,
  removeSavedSearch,
  publishAsset,
};
