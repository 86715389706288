<template>
  <b-modal ref="addEntity" :title="title" ok-title="Add" @ok="addEntity" lazy>
    <div class="d-block">
      <b-alert :show="!!newEntity.alert.msg" :variant="newEntity.alert.variant">{{ newEntity.alert.msg }}</b-alert>
      <b-form-group horizontal breakpoint="md" label="Type" label-for="addNewType" class="mb-3">
        <b-form-select v-model="newEntity.type" :options="availableTypes" id="addNewType" />
      </b-form-group>
      <b-form-group horizontal breakpoint="md" label="Title" label-for="addNewTitle">
        <b-input v-model="newEntity.name" id="addNewTitle" type="text" />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { addEntity } from '@/api/apolloQueries';
import config from '@/config';

export default {
  name: 'NewEntity',
  props: ['title', 'callback', 'availableTypes', 'defaultType', 'typeDefs', 'options'],
  data() {
    return {
      newEntity: {
        type: null,
        name: '',
        alert: {
          variant: 'danger',
          msg: '',
        },
      },
    };
  },
  methods: {
    showAddEntity() {
      this.newEntity.alert.msg = '';

      if (this.defaultType) {
        if (this.availableTypes.find(availableType => availableType.value === this.defaultType)) {
          this.newEntity.type = this.defaultType;
        } else {
          this.newEntity.alert.variant = 'warning';
          this.newEntity.alert.msg = `You don't have write access to ${this.$route.params.type}`;
          this.newEntity.type = this.availableTypes[0].value || null;
        }
      } else if (this.$route.params && this.$route.params.type && this.$route.params.type !== 'any') {
        if (this.availableTypes.find(availableType => availableType.value === this.$route.params.type)) {
          this.newEntity.type = this.$route.params.type;
        } else {
          this.newEntity.alert.variant = 'warning';
          this.newEntity.alert.msg = `You don't have write access to ${this.$route.params.type}`;
          this.newEntity.type = this.availableTypes[0].value || null;
        }
      } else {
        this.newEntity.type = this.availableTypes[0].value || null;
      }

      this.newEntity.name = '';
      this.$refs.addEntity.show();
    },
    hideAddEntity() {
      this.$refs.addEntity.hide();
    },
    addEntity() {
      this.newEntity.alert.msg = '';

      const wholeTypeDef = this.typeDefs.find(typeDef => typeDef.id === this.newEntity.type);
      if (wholeTypeDef && wholeTypeDef.titleField) {
        const titleField = wholeTypeDef.fields.find(field => field.id === wholeTypeDef.titleField);
        const name = titleField && titleField.localized ?
          {
            locale: this.options?.locale || config.defaultOptions.locale,
            value: this.newEntity.name,
          } :
          this.newEntity.name;
        addEntity(this.newEntity.type, name, wholeTypeDef.titleField).then((entityAdded) => {
          this.callback(entityAdded);
        }).catch((err) => {
          this.newEntity.alert.variant = 'danger';
          this.newEntity.alert.msg = `Adding failed: ${err.message}`;
        });
      } else {
        this.newEntity.alert.variant = 'danger';
        this.newEntity.alert.msg = 'Adding failed: Type definition not found';
      }
    },
  },
};
</script>
