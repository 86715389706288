<template>
  <div id="app">
    <b-container fluid class="main-container">
      <b-row>
        <b-col sm="4" lg="3" xl="2" class="main-menu pl-0 pr-0">
          <b-link :to="{ name: 'home' }">
            <b-img class="p-5" src="./assets/logo.png" fluid alt="Logo" />
          </b-link>
          <main-menu :permissions="user ? user.permissions : null" :typeDefs="typeDefs" />
        </b-col>
        <b-col sm="8" lg="9" xl="10" class="content">
          <b-row>
            <b-navbar v-if="!$route || !$route.meta || !$route.meta.loginLayout" toggleable="sm" type="light" variant="light" class="col">

              <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

              <b-collapse is-nav id="nav_collapse">
                <!-- Right aligned nav items -->
                <b-navbar-nav id="user-navbar" class="ml-auto">
                  <b-nav-item @click="$refs.importAssets.show()" right>
                    <font-awesome-icon class="mr-2 ml-2" icon="plus" /> <strong>{{ $t('assets.import.title') }}</strong>
                  </b-nav-item>
                  <b-nav-item @click="$refs.newEntity.showAddEntity()" right v-if="availableTypes.length > 0">
                    <font-awesome-icon class="mr-2 ml-2" icon="plus" /> <strong>Add new entity</strong>
                  </b-nav-item>
                  <b-nav-item-dropdown right v-if="user && user.permissions">
                    <!-- Using button-content slot -->
                    <template slot="button-content">
                      <font-awesome-icon class="mr-2 ml-2" icon="user" /> <strong>{{ user.firstName }} {{ user.lastName }}</strong>
                    </template>
                    <b-dropdown-item v-if="user.permissions
                      && user.permissions.grantsReadOrWriteAccess('admin', 'user')"
                      :to="{ name: 'admin-user', params: { username: user.username } }">Profile</b-dropdown-item>
                    <b-dropdown-item v-if="user.permissions
                      && (user.permissions.grantsReadOrWriteAccess('admin', 'user')
                      || user.permissions.grantsReadOrWriteAccess('admin', 'userGroup')
                      || user.permissions.grantsReadOrWriteAccess('admin', 'configuration'))"
                      :to="{ name: 'admin-home' }">Admin dashboard</b-dropdown-item>
                    <b-dropdown-item @click="logout()">Signout</b-dropdown-item>
                  </b-nav-item-dropdown>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </b-row>
          <b-row>
            <b-col class="mt-4">
              <div class="stack pb-4 w-100" v-if="stack && stack.length > 1">
                <h6>History</h6>
                <stack :stack="stack" @item-clicked="stackItemClicked" class="mb-5" />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <router-view />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <new-entity title="Add new entity" :callback="newEntityCB" :availableTypes="availableTypes" :typeDefs="typeDefs" ref="newEntity" />
    <import-assets v-if="user && user.permissions" :typeDefs="typeDefs" ref="importAssets"/>
  </div>
</template>

<script>
import { reduce } from 'lodash';
import { mapState } from 'vuex';
import MainMenu from '@/components/MainMenu.vue';
import NewEntity from '@/components/NewEntity.vue';
import Stack from '@/components/Stack.vue';
import config from '@/config';
import PerspectiveMixin from './views/PerspectiveMixin';
import ImportAssets from './components/ImportAssets.vue';

export default {
  name: 'app',
  components: { MainMenu, NewEntity, Stack, ImportAssets },
  mixins: [PerspectiveMixin],
  computed: {
    ...mapState(['user', 'typeDefs', 'options', 'stack']),
    availableTypes() {
      return reduce(this.typeDefs, (result, typeDef) => {
        if (typeDef.access === 'write') {
          result.push({
            value: typeDef.id,
            text: typeDef.name[this.options.locale] || typeDef.name[config.defaultOptions.locale],
          });
        }
        return result;
      }, []);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    newEntityCB(entityAdded) {
      // In case of new entity, go to default edit view
      this.toDefaultEditView(entityAdded.id, entityAdded.type, this.options.locale || config.defaultOptions.locale);
    },
    stackItemClicked(i) {
      this.$store.dispatch('sliceStack', i);
    },
  },
};
</script>
